/*
 * Copyright © 2023 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { useEffect, useState } from 'react'

interface WindowSize {
  height: number
  width: number
}

/**
 * Easily retrieve window dimensions with this Hook React which also works onResize.
 *
 * Public and unlicensed from https://usehooks-typescript.com/react-hook/use-window-size
 *
 * Usage:
 *
 * ```tsx
 * import React from 'react'
 * import useWindowSize from './useWindowSize'
 *
 * export const Component = () => {
 *   const { width, height } = useWindowSize()
 *
 *   return (
 *     <div>
 *       The current window dimensions are: <Code>{JSON.stringify({ width, height })}</Code>
 *     </div>
 *   )
 * }
 * ```
 */
export function useWindowSize(): WindowSize {
  const isBrowser = typeof window !== 'undefined' && window.document.documentElement

  const [windowSize, setWindowSize] = useState<WindowSize>(
    isBrowser ? { height: window.innerHeight, width: window.innerWidth } : { height: 0, width: 0 },
  )

  useEffect(() => {
    const handler = () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    // Set size at the first client-side load.
    handler()

    window.addEventListener('resize', handler)

    // Remove event listener on cleanup.
    return () => window.removeEventListener('resize', handler)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return windowSize
}
